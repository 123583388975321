import request from '@/api/request'
import pullRefresh from '@/mixins/pull-refresh'

import {
  OPUS_TYPE
} from '@/config/constant'

const mixin = {
  watch: {
    '$route': {
      handler (
        { query: { s } },
        { query: { s: oS } = {} } = {}
      ) {
        if (s !== undefined && s !== oS) {
          this.onLoad()
        }
      },
      immediate: true
    }
  }
}

export const Art = {
  data () {
    return {
      asyncFetchData: request.searchArt,
      usePager: false,
      getParams: () => ({
        queryKey: window.decodeURIComponent(this.$route.query.s)
      })
    }
  },
  mixins: [pullRefresh, mixin],
  render (h) {
    const datas = (this.datas && this.datas.opusList) || []

    if (datas.length === 0) {
      return h('van-empty', {
        props: {
          description: '暂无搜索结果'
        }
      })
    }

    const list = datas.map(({
      opusId,
      opusCode,
      authorName,
      opusType,
      height,
      width,
      years,
      title,
      thumbnailUrl
    }) => {
      const img = h('van-image', {
        props: {
          width: 120,
          height: 90,
          fit: 'cover',
          src: `${thumbnailUrl}!art_thumb` || '',
          errorIcon: 'photo-o',
          lazyLoad: true
        }
      })

      const subTitle = [
        OPUS_TYPE[opusType],
        `${width}x${height}cm` // TODO: 0
      ]

      if (years > 0) {
        subTitle.push(`${years}`)
      }

      const t = h('div', [
        h('p', { class: 't' }, title),
        h('p', { class: 'sub' }, `作品编号 ${opusCode}`),
        h('p', { class: 'sub' }, subTitle.join(' / ')),
        h('p', { class: 'sub' }, `# ${authorName}`)
      ])

      return h('div', {
        class: 's-Art',
        on: {
          click: () => {
            this.$router.push({ name: 'art', params: { id: opusId }})
          }
        }
      }, [img, t])
    })

    const wrapper = h('van-list', {
      props: {
        value: this.loading,
        finished: this.finished
      },
      on: {
        input: val => this.loading = val,
        load: evt => this.onLoad(evt)
      }
    }, list)

    return h('a-spin', {
      props: {
        spinning: this.busy
      }
    }, [
      h('van-pull-refresh', {
        class: ['p-tab-Art'],
        props: {
          value: this.refreshing
        },
        on: {
          input: val => this.refreshing = val,
          refresh: evt => this.onRefresh(evt)
        }
      }, [wrapper])
    ])
  }
}

export const Artist = {
  data () {
    return {
      asyncFetchData: request.searchArtist,
      usePager: false,
      getParams: () => ({
        queryKey: window.decodeURIComponent(this.$route.query.s)
      })
    }
  },
  mixins: [pullRefresh, mixin],
  render (h) {
    const datas = (this.datas && this.datas.userList) || []

    if (datas.length === 0) {
      return h('van-empty', {
        props: {
          description: '暂无搜索结果'
        }
      })
    }

    const list = datas.map(({
      userId,
      displayName,
      userRole,
      opusTotalNum,
      avatar,
      // location,
      userSay
    }) => {
      const img = h('van-image', {
        props: {
          width: 70,
          height: 70,
          fit: 'cover',
          src: avatar || '',
          round: true,
          errorIcon: 'photo-o',
          lazyLoad: true
        }
      })

      const t = h('div', [
        h('p', {
          class: 't'
        }, [
          displayName,
          h('span', {
            class: 'pull-right'
          }, [
            h('art-tag-role', {
              props: {
                type: userRole
              }
            })
          ])
        ]),
        h('p', {
          class: 'sub'
        }, `${opusTotalNum}件作品`),
        h('p', {
          class: 'van-ellipsis'
        }, userSay)
      ])

      return h('div', {
        class: 's-Artist',
        on: {
          click: () => {
            this.$router.push({ name: 'user-page', params: { id: userId }})
          }
        }
      }, [img, t])
    })

    const wrapper = h('van-list', {
      props: {
        value: this.loading,
        finished: this.finished,
        // finishedText: '没有更多了'
      },
      on: {
        input: val => {
          this.loading = val
        },
        load: evt => this.onLoad(evt)
      }
    }, list)

    return h('a-spin', {
      props: {
        spinning: this.busy
      }
    }, [
      h('van-pull-refresh', {
        class: ['p-tab-Artist'],
        props: {
          value: this.refreshing
        },
        on: {
          input: val => {
            this.refreshing = val
          },
          refresh: evt => this.onRefresh(evt)
        }
      }, [wrapper])
    ])
  }
}