<template>
  <div class="p-Index">
    <section v-if="ad.length">
      <div style="padding: 0px 10px">
      <van-swipe class="Banner" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="i in ad" :key="i.id">
          <van-image
              width="100%"
              height="170"
              radius="18"
              fit="cover"
              :src="i.imgAttachmentUrl"
              @click="openWindow(i.clickUrl)"
          />
        </van-swipe-item>
      </van-swipe>
      </div>
    </section>
    <section class="banner_tip">
      <div class="tip" @click="openWindow('https://mooool.com/houshan-contemporary-art-center-by-vcd-lab.html')">
        <span class="img img_a"></span>
        <span class="tit">线下美术馆</span>
      </div>
      <div class="tip" @click="openWindow('https://mp.weixin.qq.com/s/y08J-qi6o4eyKma6WEDBkg')">
        <span class="img img_b"></span>
        <span class="tit">衍生品</span>
      </div>
      <div class="tip">
        <span class="img img_c"></span>
        <span class="tit">艺术家</span>
      </div>
      <div class="tip">
        <span class="img img_d"></span>
        <span class="tit">签约设计师</span>
      </div>
    </section>
    <section v-if="recommend.length">
      <h3>推荐艺术家</h3>
      <van-swipe :loop="false" :show-indicators="false" :width="80 + 20">
        <van-swipe-item v-for="i in recommend" :key="i.userId">
          <art-card-artist
              width="80"
              height="80"
              :name="i.displayName"
              :location="i.location"
              :number="i.orderNum"
              :src="i.avatar"
          />
        </van-swipe-item>
      </van-swipe>
    </section>
    <section  @scroll="getOpusImmediate">
      <div class="p-ArtIndex">
        <div class="NotSearch" v-if="!sVisible">
          <van-sticky>
            <div class="filters van-hairline--bottom">
              <van-dropdown-menu>
                <van-dropdown-item :title="activeType[1]" ref="opusType">
                  <div class="opusType">
                    <van-row gutter="8">
                      <van-col v-for="t in types" span="6" :key="t[0]">
                    <span
                        :class="[
                        'opusType-item',
                        { active: t[0] === activeType[0] }
                      ]"
                        @click="onTypeChange(t)"
                    >{{ t[1] }}</span>
                      </van-col>
                    </van-row>
                  </div>
                </van-dropdown-item>
              </van-dropdown-menu>
              <span class="center">检索到{{ totalCount }}件作品</span>
              <span
                  class="pull-right"
                  @click="filterVisible = true"
              >筛选
            <van-icon class-prefix="icon-artalliance" name="filter" size="16" />
          </span>
            </div>
          </van-sticky>
          <div style="padding: 10px 10px 0;">
          <a-spin :spinning="loading">
            <div class="content" v-if="recommendOpus.length">
                <div class="img-container"  v-for="i in recommendOpus" v-bind:key="i.id" >
                  <img  @click="$router.push({ name: 'art', params: { id: i.opusId }})" class="waterfall-img" style="width:100%;height:50vmin;" :alt="i.title" :src="i.thumbnailUrl"/>
                  <div style="border-radius:0px 0px 10px 10px; width:100%;height:30px;overflow:hidden;text-overflow:ellipsis;white-space: nowrap;text-align:left;padding:6px;bottom:32px; position: relative; font-size: 14px; background: rgba(0,0,0,.5);color: #fff;">《{{i.title}}》</div>
                  <div style="margin-top: -30px; color: #9E9E9E"> {{ i.width }}X{{ i.height }}cm／{{ i.years }}</div>
                  <div class="tit_zz">
                    <span class="cart_icon"></span>
                    <span @click="$router.push({ name: 'user-page', params: { id:i.authorId}})" style="padding-left: 6px;">
                      <img :src="i.avatar" style="width:15px; height: 15px; border-radius: 50%; position: relative; top: -2px;">
                      <span class="author"> {{i.authorName}}</span>
                    </span>
                  </div>
                  <div class="tit_sc">
                    <div class="sc_item"><span class="icon"></span>已收藏</div>
                    <div style=" float: right; padding:6px 10px;overflow:hidden;text-overflow:ellipsis;white-space: nowrap;display:flex;justify-content:space-between;align-content:center;bottom:0px;">浏览: {{~~(Math.random()*300)}}</div>
                  </div>
                </div>
            </div>
            <van-empty v-else description="暂无作品" />
          </a-spin>
          </div>
        </div>
        <a-drawer
            placement="right"
            wrap-class-name="drawer-filter"
            :visible="filterVisible"
            :closable="false"
            width="calc(100vw - 55px)"
            @close="filterVisible = false"
        >
          <div>
            <template
                v-for="(o, $index) in [
            {
              name: '设计风格',
              key: 'designType'
            },
            {
              name: '色系',
              key: 'colorType'
            },
            {
              name: '适合场景',
              key: 'sceneType'
            },
            {
              name: '作品主体',
              key: 'subjectType'
            }
          ]"
            >
              <div
                  v-if="filters[o.key]"
                  :key="$index"
              >
                <h5>{{ o.name }}</h5>
                <art-radio-group
                    v-model="filters[o.key].value"
                    :options="filters[o.key].options"
                    :span="8"
                    :gutter="10"
                    multiple
                />
              </div>
            </template>
            <template v-if="filters.widthSize">
              <h5>尺寸（宽度）</h5>
              <art-radio-group
                  v-model="filters.widthSize.value"
                  :options="filters.widthSize.options"
                  :gutter="10"
              />
            </template>
            <template v-if="filters.price">
              <h5>参考价</h5>
              <div class="range">
                <input
                    v-model="filters.price.min"
                    placeholder="最低价"
                />
                <span>-</span>
                <input
                    v-model="filters.price.max"
                    placeholder="最高价"
                />
              </div>
            </template>
            <div class="btns">
              <van-button type="info" @click="reset" plain size="small">重&nbsp;置</van-button>
              <van-button type="info" @click="fetchData" size="small">确&nbsp;定</van-button>
            </div>
          </div>
        </a-drawer>
      </div>
    </section>
  </div>
</template>

<script>
import request from '@/api/request'
import utils from '@/utils'
import store from '@/store'
import {Art, Artist} from "./art/tab-components"

import {
  OPUS_TYPE,
  HISTORY_COLLENCTION,
  HISTORY_LIMIT,
  DEFAULT_AVATAR,
  ART_SIZE
} from '@/config/constant'
import {mapGetters} from "vuex";


const RECOMMEND_STORE_KEY = 'RSK'

export default {
  data () {
    const fullOpusType = {
      0: '全部分类',
      ...OPUS_TYPE
    }
    const types = Object.keys(fullOpusType).map(
        type => ([Number(type), fullOpusType[type]])
    )

    types.splice(1, 0, [-1, '精选作品'])

    const tabs = [
      { title: '作品', name: 'art' },
      { title: '艺术家', name: 'artist' }
    ]
    return {
      ad: [],
      recommend: [],
      recommendOpus: [],
      news: [],
      loading:false,
      tagList: {},
      // opusType
      types,
      activeType: types[0],
      // pager
      datas: [],
      page: 1,
      pageSize: 20,
      pageCount: 0,
      totalCount: 0,
      //filters: {"widthSize":{"options":["20-40cm","40-60cm","60-100cm","100-200cm","200cm以上"],"value":[]},"price":{"min":"","max":""},"designType":{"options":["测试3333","冷淡风","极简风"],"value":[]},"sceneType":{"options":["客厅","卧室"],"value":[]},"subjectType":{"options":["风景","人物"],"value":[]},"commissionType":{"options":["D类作品","C类作品","B类作品","A类作品"],"value":[]},"colorType":{"options":["黄色系","红色系"],"value":[]}},
      //filters:{},
      filterVisible: false,
      // tabs
      tabs,
      sType: 0,
      // search
      s: '',
      sPrev: '',
      sEmpty: true,
      sHistory: [],
      sVisible: false,
      sDatas: [],
      sOnInput: null
    }
  },
  watch: {
    sType (type) {
      if (type !== undefined) {
        const { name, query } = this.$route

        this.$router.push({
          name,
          query: {
            ...query,
            type
          }
        })
      }
    }
  },
  mounted() {
    this.scrollWindow();
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  methods: {
    onTypeChange (type) {
      this.recommendOpus=[];
      this.activeType = type

      const trigger = this.$refs.opusType

      if (trigger) {
        this.page = 1
        // TODO
        this.fetchData()
        trigger.toggle()
      }
    },
    reset () {
      this.filters = utils.cloneDeep(this.initialFilters)
      this.$nextTick(this.fetchData.bind(this))
    },
    fetchData () {
      const { activeType, page, pageSize, filters } = this
      const {
        price: {
          min,
          max
        },
        ...others
      } = filters

      const params = {
        currPage: page,
        pageSize
      }

      if (activeType[0] === 0) {
        params.opusType = undefined
      } else if (activeType[0] === -1) {
        params.isDeposit = 1
      } else {
        params.opusType = activeType[0]
      }

      if (min !== '') {
        params.priceBegin = Number(min)
      }

      if (max !== '') {
        params.priceEnd = Number(max)
      }

      Object.keys(others).forEach(key => {
        const values = filters[key].value.slice()

        if (Array.isArray(values) && values.length) {
          if (key === 'widthSize') {
            const oKey = values.map(({ name }) => name).join(',')

            if (oKey && ART_SIZE[oKey]) {
              params[key] = ART_SIZE[oKey]
            }
          } else {
            params[key] = values.map(({ name }) => name).join(',')
          }
        }
      })

      this.loading = true
      request.getList(params).then(res => {
        console.log(res);
        const {
          totalCount,
          totalPage,
          list = []
        } = res

        this.totalCount = totalCount
        this.pageCount = totalPage
        this.datas = list.map(o => {
          if (!o.avatar) {
            o.avatar = DEFAULT_AVATAR
          }
          if (o.authorId && o.authorId>10000000 && o.thumbnailUrl && /(\d{15,20})/.test(o.thumbnailUrl)) {
            o.authorId = RegExp.$1;
          }

          return o
        })

        if(totalPage>this.page){
          this.page++;
          this.recommendOpus=this.recommendOpus.concat(this.datas);
        }else{
          this.recommendOpus=[].concat(this.datas);
        }
        this.filterVisible = false
        //utils.scrollTo(0)

        this.loading = false
      })
    },
    onSearch () {
      const s = utils.trim(this.s)

      if (
          (this.sEmpty = s === '') ||
          (this.sPrev === s)
      ) {
        return
      }

      const history = this.sHistory.slice()
      const idx = history.indexOf(s)

      if (idx > -1) {
        history.splice(idx, 1)
      }

      history.push(s)
      this.sHistory = history.slice(0, HISTORY_LIMIT)

      const { name, query } = this.$route
      const encode = window.encodeURIComponent(s)

      this.$router.push({
        name,
        query: {
          ...query,
          s: encode
        }
      })

      this.sPrev = s
    },
    onFocus () {
      const history = this.$ls.get(HISTORY_COLLENCTION)

      this.sHistory = Array.isArray(history) ? history : []
      this.sVisible = true
    },
    // TODO
    onCancel () {
      this.sVisible = false
      this.saveHistory()

      this.page = 1
      this.fetchData()

      this.$router.push({ name: this.$route.name })
      this.sDatas = []
    },
    clickOnHistory (s) {
      this.s = s
      this.onSearch()
    },
    saveHistory () {
      this.$ls.set(HISTORY_COLLENCTION, this.sHistory)
    },
    clearHistory () {
      this.$ls.set(HISTORY_COLLENCTION, this.sHistory = [])
    },
    components: { Art, Artist },
    scrollWindow() {
      window.addEventListener('scroll', () => {
        if (isScrollToBottom()) { 
          if(!this.loading)this.fetchData();
        }
      })
    },
    getOpus () {
      const res = this.$ls.get(RECOMMEND_STORE_KEY)

      if (res === null) {
        this.getOpusImmediate()
      } else {
        this.recommendOpus = res
      }
    },
    getOpusImmediate() {
      this.loading = true;
      request.getRecommend().then(res => {
        this.loading = false;
        if (res.opus !== undefined) {
          const recommendOpus = [].map.call(
              res.opus || [], o => {
                if (utils.isEmpty(o.avatar)) {
                  o.avatar = DEFAULT_AVATAR
                }

                return o
              }
          )

          this.recommendOpus = this.recommendOpus.concat(recommendOpus)
          this.$ls.set(RECOMMEND_STORE_KEY, recommendOpus, 1e3 * 60 * 5)
        } else {
          const keys = Object.keys(res)

          if (keys.length === 0) {
            return
          }

          const firstKey = keys.pop()

          if (firstKey !== undefined) {
            res = res[firstKey]

            if (res.opus !== undefined) {
              const recommendOpus = [].map.call(
                  res.opus || [], o => {
                    if (utils.isEmpty(o.avatar)) {
                      o.avatar = DEFAULT_AVATAR
                    }

                    return o
                  }
              )
              this.$ls.set(RECOMMEND_STORE_KEY, recommendOpus, 1e3 * 60 * 5)
              this.recommendOpus = recommendOpus
            }
          }
        }
      })
    },
    async getAds () {
      const { carouselList: ad = [] } = await request.getAd()
      const { artActivityList: news = [] } = await request.getNews()

      this.ad = ad || []
      this.news = news || []
    },
    openWindow (url) {
      return utils.openWindow(url)
    }
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('GetInfo',userInfo => {
      console.log('indexuserinfo='+JSON.stringify(userInfo));
      localStorage.setItem('userInfo',JSON.stringify(userInfo))
    })
    store.dispatch('GetTags', tags => {
      return next(vm => {
        // add initial value
        for (const o in tags) {
          tags[o].value = tags[o].value || []
        }

        vm.initialFilters = {
          widthSize: {
            options: Object.keys(ART_SIZE),
            value: []
          },
          price: {
            min: '',
            max: ''
          },
          ...tags
        }
        // set filter before Enter
        vm.filters = utils.cloneDeep(vm.initialFilters)

        vm.fetchData()
        vm.sOnInput = utils.throttle(
            vm.onSearch.bind(vm),
            2000, {
              leading: true,
              trailing: true
            }
        )
      })
    })
  },
  created () {
    this.getAds()
    // TODO
    const active = this.$route.query.type

    if (active !== undefined && (active !== this.activeType[0])) {
      for (let i = 0; i < this.types.length; i += 1) {
        if (active === this.types[i][0]) {
          this.activeType = this.types[i]
          break;
        }
      }
    }
  },
  beforeDestroy () {
    this.saveHistory()
  }
}

function isScrollToBottom() {
  const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
  const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
  return scrollTop + clientHeight >= scrollHeight-50;
}
</script>

<style lang="less">

.p-Art .block .sub .van-button{
  font-size: 13px;
  line-height: 28px;
  height: 28px;
}
  .content {
    //column-count: 2;
    //margin:0px;
    .tit_zz{ border-bottom: 1px #EBEBEB solid; padding-bottom: 3px;}
    .cart_icon{ width: 18px;height: 15px; float: right;background: #000000; margin-right: 8px; margin-top: 2px;
      background: url("../../public/static/image/cart_icon.png") no-repeat;
      background-size: cover;
    }
    .sc_item{ float: left; width: 40%; padding: 6px 0;
      .icon{
        width: 16px; height: 16px; margin-right: 3px; display: block; float: left; margin-top: 2px;
        background: url("../../public/static/image/sc_icon_2.png") no-repeat;
        background-size: cover;
      }
    }
  }
  .img-container{
    margin-bottom: 15px;
    border-radius:10px;
    width: 50%;
    padding: 0 5px;
    float: left;
    background: #fff;
    position:relative;
  }
 .waterfall-img{
    width:100%;
   border-radius:10px;
 }

.p-Index {
  padding: 20px 0px @tabbar-height;
  min-height: 100vh;
  background-color: #F7F8FA;

  section {
    margin-bottom: 20px;

    h3 {
      font-size: 16px;
      margin-bottom: 20px;
      color: #040404;

      .pull-right {
        font-size: 12px;
        line-height: 24px;
      }
    }
  }

  .van-swipe {
    line-height: 0;

    .van-multi-ellipsis--l2 {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 20px;
      height: 40px;
      line-height: 40px;
      background-color: rgba(0,0,0,.25);
      z-index: 9;
      border-radius: 0 0 6px 6px;
      overflow: hidden;

      & > span {
        color: #fff;
        font-size: 14px;
        text-indent: 15px;
        display: block;
      }
    }
  }

  .banner_tip{ padding:0 20px;display: flex;
    .tip{flex-wrap: nowrap; text-align: center; flex: auto;
      .img{ width: 64px; height: 64px; display: inline-block;
        &.img_a{
          background: url("../../public/static/image/msg_icon.png") no-repeat;
          background-size: cover;
        }
        &.img_b{
          background: url("../../public/static/image/ysp_icon.png") no-repeat;
          background-size: cover;
        }
        &.img_c{
          background: url("../../public/static/image/ysj_icon.png") no-repeat;
          background-size: cover;
        }
        &.img_d{
          background: url("../../public/static/image/sjs_icon.png") no-repeat;
          background-size: cover;
        }

      }
      .tit{display: block;

      }
    }
  }

  .CardArt {
    margin-bottom: 20px;
    display: block;

    .van-multi-ellipsis--l2 {

      .van-image {
        vertical-align: top;
        margin-right: 4px;

        + span {
          width: calc(100% - 22px);
          word-break: break-all;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
}

.Banner {
  color: #fff;
  font-size: 20px;
  line-height: 0;
  border-radius: 18px;
  overflow: hidden;
  .van-image img {
    border-radius: 18px;
  }
}
.p-ArtIndex {
  min-height: 100vh;
  background-color: #F7F8FA;

  .van-search {
    padding: 10px 16px;
  }

  .filters {
    background-color: #fff;
    padding: 0 18px;
    position: relative;

    .opusType {
      padding: 15px;
    }

    .opusType-item {
      background-color: #F7F8FA;
      border-radius: 2px;
      width: 100%;
      font-size: 13px;
      color: #323233;
      line-height: 32px;
      display: inline-block;
      text-align: center;
      margin-bottom: 12px;

      &.active {
        background-color: #040404;
        color: #fff;
      }
    }

    & > .van-dropdown-menu {
      height: 36px;
      display: inline-block;
      padding-top: 9px;

      &:after {
        content: none;
      }

      .van-dropdown-menu__title {
        font-size: 13px;
      }
    }

    .center {
      position: absolute;
      top: 9px;
      text-align: center;
      left: 6em;
      right: 6em;
      margin: auto;
      color: #B4B4B4;
    }

    & > span {
      font-size: 13px;
      line-height: 18px;
      display: inline-block;
    }

    .pull-right {
      margin-top: 9px;

      .icon-artalliance {
        vertical-align: top;
      }
    }
  }

  .Search {

    .van-tabs__wrap {
      padding: 0 12.5%;
      margin: 0 auto 4px;
      background-color: #fff;

      &:after {
        content: none;
      }
    }

    .van-tabs__line {
      bottom: 50%;
      margin-bottom: -2px;
      background-color: #B4B4B4;
      border-radius: 0 !important;
      opacity: .5;
    }
  }

  .NotSearch {

    .filter {
      position: absolute;
      background-color: #f8f8f8;

      .van-sidebar {
        width: 100px;
        text-align: center;

        + div {
          position: absolute;
          top: 0;
          left: 100%;
          width: calc(100vw - 100px);
          padding: 16px 12px 0;
          box-sizing: border-box;
          background-color: #fff;

          > span {
            color: #aaa;
            font-size: 12px;
            line-height: 18px;
            display: inline-block;
            vertical-align: top;
          }
        }
      }
    }
  }

  .Empty {
    padding: 20px;
    min-height: calc(100vh - 54px - @tabbar-height);
    background-color: #fff;

    h3 {
      margin-bottom: 16px;
    }

    h5 {
      margin-right: 10px;
    }

    .van-button {
      margin-bottom: 10px;
      margin-right: 10px;
      color: #9E9E9E;
      background-color: #F7F8FA;
      border-color: #F7F8FA;
      border-radius: 2px;
    }
  }

  .NotEmpty {
    min-height: calc(100vh - 54px - 44px - @tabbar-height);
  }

  .context {
    padding: 20px 0px 20px + @tabbar-height + 36px;

    .van-pagination {
      margin-bottom: 0;
    }

    .CardArt {
      margin-bottom: 20px;
      display: block;

      .van-multi-ellipsis--l2 {

        .van-image {
          vertical-align: top;
          margin-right: 4px;

          + span {
            width: calc(100% - 22px);
            word-break: break-all;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
  }
}
</style>

<style lang="less">
.s-Art {
  padding: 15px 20px;
  background-color: #fff;
  margin-bottom: 2px;
  font-size: 0;

  .van-image {

    img {
      border-radius: 4px;
    }

    & + div {
      width: calc(100% - 120px);
      padding-left: 15px;
      display: inline-block;
      vertical-align: top;

      .t {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 8px;
      }

      .sub {
        color: #B4B4B4;
        font-size: 12px;
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.s-Artist {
  padding: 15px 20px;
  background-color: #fff;
  margin-bottom: 2px;
  font-size: 0;

  .van-image + div {
    width: calc(100% - 70px);
    padding-left: 15px;
    display: inline-block;
    vertical-align: top;

    .t {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 4px;
    }

    .sub {
      color: #B4B4B4;
      font-size: 12px;
      margin-bottom: 8px;
    }

    p {
      color: @gray-8;
      font-size: 12px;
      margin-bottom: 0;
    }
  }
}

.drawer-filter {

  .ant-drawer-body {
    padding: 25px 15px 30px;
  }

  h5 {
    margin-bottom: 15px;
  }

  .RadioGroup {
    margin-bottom: 20px;
  }

  .van-col > .van-button {
    background-color: #F7F8FA;
    border-radius: 15px;
    border: 0;
    color: #323233;
    font-size: 12px;

    &.van-button--info {
      background-color: #040404;
      color: #fff;
    }
  }

  .van-cell {
    font-size: 12px;
    padding: 5px 0;
  }

  .range {
    margin-bottom: 20px;

    input {
      width: calc(50% - 20px);
      font-size: 12px;
      line-height: 18px;
      padding: 6px;
      border-radius: 15px;
      background-color: #F7F8FA;
      border: 0;
      text-align: center;
    }

    span {
      width: 40px;
      text-align: center;
      display: inline-block;
      line-height: 30px;
      vertical-align: top;
    }
  }

  .btns {
    text-align: right;
    padding-top: 40px;

    .van-button {
      padding: 0 20px;
      margin-left: 10px;
    }
  }
}
</style>